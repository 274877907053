import { StyleSheet, View, Button, Text } from 'react-native';

const HomeScreen = ({navigation, route}) => {
    return (
      <View style={styles.appContainer}>
        <Text>Ensemble, construisons votre santé</Text>
        <Text>Une photo de votre langue va vous aider à connaître votre intérieur, vous avez le droit de tirer la langue!</Text>
        <Button
            title="M'auto-évlauer!"
            onPress={() =>
                navigation.navigate('Evaluation')
            }
        />
      </View>
    );
};

export default HomeScreen;

const styles = StyleSheet.create({
    appContainer: {
      flex: 1,
      paddingTop: 50,
      paddingHorizontal: 16,
      margin: 10,
    },
    usersContainer: {
      flex: 5,
    },
  });
