import { View, Text, Image} from 'react-native';

const ProfileScreen = ({ navigation, route }) => {
  return (
   <View>
      <Text>This is the profile screen.</Text>
   </View>
  );
}

export default ProfileScreen;
