import { View, Image, Text, StyleSheet } from 'react-native';

const ResultsScreen = ({ navigation, route }) => {
    const { image } = route.params;
    return (
    <View style={styles.container}>
         <Image source={{ uri: image }} style={styles.image}/>
         <Text>Merci pour votre participation! Si vous nous avez fourni votre adresse, nous vous contacterons dans les meilleurs délais avec les résultats.</Text>
    </View>
    );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    flex: 1,
    width: '50%',
    height: '50%',
    resizeMode: 'contain'
  },
});

export default ResultsScreen;


