import { StyleSheet, View, SafeAreaView, FlatList, Text, Button } from 'react-native';
import { useState, useEffect } from 'react';
import { NavigationContainer, Stack } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import ConnectionScreen from './components/ConnectionScreen'
import EvaluationScreen from './components/EvaluationScreen'
import HomeScreen from './components/HomeScreen'
import HistoryScreen from './components/HistoryScreen';
import ProfileScreen from './components/ProfileScreen';
import ResultsScreen from './components/ResultsScreen';

export default function App() {
  const Stack = createNativeStackNavigator();
  
  return (
    <SafeAreaView style={styles.appContainer}>
      <NavigationContainer>
        <Stack.Navigator>
          <Stack.Screen
            name="Landing"
            component={ConnectionScreen}
            options={{title: 'Bienvenue'}}
          />
          <Stack.Screen 
            name="Home"
            component={HomeScreen}
            options={{title: 'Guidus'}}
          />
          <Stack.Screen 
            name="Profile"
            component={ProfileScreen} 
          />
          <Stack.Screen 
            name="Evaluation"
            component={EvaluationScreen} 
          />
          <Stack.Screen 
            name="History"
            component={HistoryScreen} 
          />
          <Stack.Screen 
            name="Results"
            component={ResultsScreen} 
          />
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
    paddingTop: 1,
    paddingHorizontal: 16,
    margin: 10,
  },
  usersContainer: {
    flex: 5,
  },
});

