import React, { useEffect, useState } from 'react';
import { View, Button, StyleSheet, Image } from 'react-native';
import { Camera } from 'expo-camera';
import { Asset } from 'expo-asset';
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';

const EvaluationScreen = ({ navigation, route }) => {
  const [hasCameraPermission, setHasCameraPermission] = useState(null);
  const [camera, setCamera] = useState(null);
  const [image, setImage] = useState(null);
  const [type, setType] = useState(Camera.Constants.Type.front);
  const { mail } = route.params;

  useEffect(() => {
    (async () => {
      let cameraStatus = await Camera.requestCameraPermissionsAsync(); 
      while(!cameraStatus.granted){
        cameraStatus = await Camera.requestCameraPermissionsAsync();
      }
      setHasCameraPermission(cameraStatus.status === 'granted');
      //alert(cameraStatus.status);
    })();
  }, []);

  const generateRandomString = (length = 20) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
  
    return result;
  };

  const takePicture = async () => {
    if (camera) {
      const data = await camera.takePictureAsync(null);
      const store = await manipulateAsync(data.localUri || data.uri,
        [
            { rotate: 180 },
            { flip: FlipType.Vertical },
        ],
        { compress: 1, format: SaveFormat.JPEG }
      );
      setImage(store.uri);
      return store.uri;
    }
  };

  const performPostRequest = async (image_uri) => {
    try {
      const apiUrl = 'https://api.maxoway.app/';
      const filename = generateRandomString() + '.jpg';
      console.log(filename);
      console.log(image_uri);

      if (image_uri) {
        let formData = new FormData();
        if (mail === 'No mail') {
          formData.append('anonymous', true)
          formData.append('mail', 'None')
        }
        else {
          formData.append('anonymous', false)
          formData.append('mail', mail)
        }
        formData.append('name', filename);
        formData.append('type', 'image/jpeg');
        formData.append('photo', new File([image_uri], filename, { type: 'image/jpeg' }));
    
        console.log("Fetching tongue...");
        const response = await fetch(apiUrl, {
          method: 'POST',
          body: formData,
        });
    
        //console.log('Raw FormData:', formData);
    
        if (!response.ok) {
          throw new Error('Network response error');
        }

        console.log("Retrieving...");
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        const downloadedImage = Asset.fromURI(imageUrl);
        await downloadedImage.downloadAsync();
        return downloadedImage.localUri;
      }
      else {
        console.log("No image sent!")
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };
  
  return (
    <View style={{ flex: 1 }}>
      {image ? (
        <View style={{ flex: 1 }}>
          <Image source={{ uri: image }} style={{ flex: 1 }} />
          <View style={{ flexDirection: 'row', justifyContent: 'space-around'}}>
            <Button
              title="Envoyer"
              onPress={() => {
                navigation.reset({
                  index: 0,
                  routes: [{ name: 'Results', params: {
                    image: image
                  } }],
                });
              }}
            />
            <Button
              title="Reprendre la photo"
              onPress={async () => {
                setImage(null)
              }}
            />
          </View>
        </View>
      ):(
        <View style={{ flex: 1 }}>
          <Camera
            ref={(ref) => setCamera(ref)}
            type={type}
            ratio={'1:1'}
          >
          <View style={styles.overlay}>
            <Image source={Asset.fromModule(require('../assets/mask_tongue_small.png'))} style={styles.png}/>
          </View>
          </Camera>
          <Button 
            title="Capturer ma langue"
            onPress={async () => {
                const image_uri = await takePicture();
                const imageRec = await performPostRequest(image_uri);
                setImage(imageRec);
              }
            } 
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  png: {
    flex: 1,
    width: '70%',
    height: '70%',
    resizeMode: 'contain'
  },
});

export default EvaluationScreen;
