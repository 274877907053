import React, { useState } from 'react';
import { StyleSheet, View, Button, TextInput, Modal, Text } from 'react-native';
import { Client, Account, ID } from 'appwrite';

const ConnectionScreen = ({ navigation, route }) => {
  const [showModal, setShowModal] = useState(false);
  const [email, setMail] =  useState(false);
  const [validMail, setValid] = useState(false);

  const client = new Client()
    .setEndpoint('https://128.140.127.16:1443/v1')
    .setProject('65a6bda6ca794b646ce5');
  const account = new Account(client);

  const validate = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
      setMail(text)
      setValid(false);
    }
    else {
      setMail(text)
      setValid(true)
    }
  }

  return (
    <View style={styles.appContainer}>
      <Text>La langue est utilisée depuis plusieurs millénaires pour donner une information sur la nature du terrain humain et de ses déséquilibres. C'est le seul muscle qui se trouve à l'intérieur du corps, de la bouche et que l'on peut voir de l'extérieur. C'est pourquoi, la photo de votre langue va vous aider à connaître votre intérieur</Text>
      <View style={styles.buttons}>
        <Button
          title="Participer!"
          onPress={() => {
            setShowModal(true);
          }}
        />
        <Button
          title="Participer anonymement"
          onPress={() =>
            navigation.reset({
              index: 0,
              routes: [{ name: 'Evaluation' , params: {
                mail: 'No mail'
              }}],
            })
          }
        />
      </View>
      <Modal transparent={true} visible={showModal}>
        <View style={styles.modalContainer}>
          <Text style={{color: 'white'}}>Entrez votre mail</Text>
          <TextInput
            style={styles.input}
            placeholder="Votre mail"
            keyboardType="email"
            onChangeText={(text) => validate(text)}
          />
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: '5px'}}>
            <Button
              title="On y va!"
              onPress={() => {
                if (validMail === true) {
                  setShowModal(false);
                  navigation.reset({
                    index: 0,
                    routes: [{ name: 'Evaluation', params: {
                      mail: email
                    } }],
                });
                }
              }}
            />
            <Button
              title="Retour"
              onPress={() => {
                setShowModal(false);
              }}
            />
          </View>
        </View>
      </Modal>
    </View>
  );
};

export default ConnectionScreen;

const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
    justifyContent: 'space-around',
    paddingTop: 50,
    paddingHorizontal: 16,
    margin: 10,
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
  input: {
    height: 40,
    margin: 12,
    padding: 12,
    borderWidth: 1,
    color: 'white',
  },
  buttons: {
    flex: 1,
    gap: 10,
    justifyContent: 'center',
  }
});
